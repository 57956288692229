import i18next from 'i18next'

import PhasmoI18n from './localisation.bytes'

I18nKeys = do PhasmoI18n
  .slice 1
  .map (row) -> row[0]
  .sort

export loadingHints = do ->
  I18nKeys
    .filter (key) -> key.match /Loading_Hint\d/

export phraisesHints = do ->
  prefixes = [
    'Phrase'
    'Ouija'
    'Q'
    'Loading_Hint'
  ]
  I18nKeys
    .filter (key) -> prefixes.some (prefix) -> key.startsWith "#{prefix}_"

export allPrefixes = do ->
  keys = I18nKeys
    .filter (key) -> not key.startsWith '_'
    .map (row) -> row.split('_')[0]
  do Array.from(new Set keys).sort

export default ->
  locales = PhasmoI18n[0].slice 1
  data = locales.map -> {}

  PhasmoI18n
    .slice 1
    .forEach ([key, translation...]) ->
      translation.forEach (t, index) ->
        data[index][key] = do t.trim

  for locale, index in locales
    i18next.addResourceBundle locale , 'phasmophobia', data[index]

# export default ->
#   locales = PhasmoI18n[0].slice 1
#   data = {}
#   for locale in locales
#     data[locale] =
#       phasmophobia: {}

#   PhasmoI18n
#     .slice 1
#     .forEach ([key, translation...]) ->
#       translation.forEach (t, index) ->
#         data[locales[index]]['phasmophobia'][key] = t

#   for locale, index in locales
#     i18next.addResourceBundle locale , 'phasmophobia', data[index]
#   data
