import React, { Fragment, useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { Provider, useSelector, useDispatch, useStore } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { matchSorter } from 'match-sorter'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

import GhostNames from './ghost_names.coffee'
import {
  SET_GHOST_NAME
  RESET_CONTRACT
  TOGGLE_ALONE_GHOST
  SET_MAP
  SET_LEVEL
} from './redux.coffee'

useStyles = makeStyles
  autocomplete:
    flexGrow: 1
    minWidth: '200px'
  input:
    marginTop: 0
    marginBottom: 0

export GhostNameInput = ->
  { t } = do useTranslation
  dispatch = do useDispatch
  classes = do useStyles
  name = useSelector (store) -> store.contract.name
  [input, setInput] = useState name
  useEffect ->
    setInput name unless name is input
  , [name]

  handleName =      (event, name) -> dispatch type: SET_GHOST_NAME, value: name
  handleInputName = (event, name) -> dispatch type: SET_GHOST_NAME, value: name

  onChange = (event, value) ->
    setInput value
    dispatch type: SET_GHOST_NAME, value: value
  onInputChange = (event, value, type) ->
    dispatch type: SET_GHOST_NAME, value: value if type is 'clear'
    setInput value

  lastClose = useRef 'blur'
  onClose = ({target}, type) ->
    if type is 'blur' and lastClose.current isnt 'select-option'
      dispatch type: SET_GHOST_NAME, value: target.value
    lastClose.current = type

  <Autocomplete
    freeSolo
    options={GhostNames}
    className={classes.autocomplete}
    getOptionLabel={(x) -> x or ''}
    filterOptions={(options, { inputValue, getOptionLabel }) ->
      matchSorter options, inputValue
    }
    onChange={onChange}
    inputValue={input or ''}
    onInputChange={onInputChange}
    onClose={onClose}
    selectOnFocus
    blurOnSelect
    handleHomeEndKeys
    renderInput={(params) -> (
      <TextField
        {...params}
        className={classes.input}
        label={t 'ghost_name'}
        margin='normal'
        variantx='outlined'
        value={input}
      />
    )}
  />
