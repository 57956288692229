import React, { Fragment, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AnyControl from 'anycontrol'

import {
  CONFIRM_EVIDENCE
  REJECT_EVIDENCE
  RESET_CONTRACT
  TIMER_CLEAR
  TIMER_START
} from './redux.coffee'

mapAll = (mapTo, sayings...) -> sayings.map (saying) -> [saying, mapTo]

evidenceMap = new Map [
  ...mapAll 'EMF Level 5', 'emf', 'emf level five', 'emf level 5', 'emf five', 'emf 5'
  ...mapAll 'Fingerprints', 'fingerprints', 'fingies', 'thingies'
  ...mapAll 'Ghost Orb', 'ghost orbs', 'ghost orb', 'go storms', 'orbs', 'orb'
  ...mapAll 'Ghost Writing', 'ghostwriting', 'ghost writing', 'ghost rider'
  ...mapAll 'Freezing Temps', 'freezing temps', 'freezing', 'freezing temperatures'
  ...mapAll 'Spirit Box', 'spirit box'
]

evidenceCommands = new Map [
  ...mapAll CONFIRM_EVIDENCE, 'we have ${evidence}', 'we got ${evidence}', "we've got ${evidence}", 'confirm ${evidence}', '${evidence} confirmed'
  ...mapAll REJECT_EVIDENCE, "it's probably not ${evidence}", "i don't think it's ${evidence}"
]
commands = new Map [
  ...mapAll RESET_CONTRACT, 'clear my journal', 'reset my journal', 'clear my contract', 'reset my contract'
  ...mapAll TIMER_START, 'start timer'
  ...mapAll TIMER_CLEAR, 'clear timer'
]

filteredDispatch = (dispatch, type) ->
  ({evidence}) ->
    if evidenceMap.has evidence
      dispatch type: type, name: evidenceMap.get(evidence)

export default ({children = null}) ->
  ctrl = useRef new AnyControl
  dispatch = do useDispatch
  useEffect ->
    # ctrl.current.debug true
    evidenceCommands.forEach (action, command) ->
      ctrl.current.addCommand command,
        filteredDispatch dispatch, action
    commands.forEach (action, command) ->
      ctrl.current.addCommand command, ->
        dispatch type: action
    do ctrl.current.start
    -> do ctrl.current.stop
  , [ctrl, dispatch]

  children
