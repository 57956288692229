import { List } from 'immutable'

male = [
  'Charles'
  'Christopher'
  'Daniel'
  'David'
  'Donald'
  'George'
  'James'
  'John'
  'Joseph'
  'Kenneth'
  'Mark'
  'Michael'
  'Paul'
  'Richard'
  'Robert'
  'Steven'
  'Thomas'
  'William'
]

female = [
  'Barbara'
  'Betty'
  'Carol'
  'Donna'
  'Dorothy'
  'Elizabeth'
  'Helen'
  'Jennifer'
  'Karen'
  'Linda'
  'Lisa'
  'Margaret'
  'Maria'
  'Mary'
  'Nancy'
  'Patricia'
  'Ruth'
  'Sandra'
  'Susan'
]

surnames = [
  'Anderson'
  'Brown'
  'Clark'
  'Davis'
  'Garcia'
  'Harris'
  'Jackson'
  'Johnson'
  'Jones'
  'Martin'
  'Martinez'
  'Miller'
  'Moore'
  'Robinson'
  'Smith'
  'Taylor'
  'Thomas'
  'Thompson'
  'Williams'
  'Wilson'
  'White'
]

export default do ->
  names = []
  for surname in surnames
    for name in female
      names.push "#{name} #{surname}"
    for name in male
      names.push "#{name} #{surname}"
  do names.sort
  Object.freeze names
