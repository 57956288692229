import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core'

import deepPurple from '@material-ui/core/colors/deepPurple'
import blueGrey from '@material-ui/core/colors/blueGrey'
# import lightGreen from '@material-ui/core/colors/lightGreen'
# import teal from '@material-ui/core/colors/teal'
# import grey from '@material-ui/core/colors/blueGrey'
# import brown from '@material-ui/core/colors/brown'

# addAlpha = (color, alpha) ->
#   Object.keys(color).
#     reduce (n, k) ->
#       n[k] = color[k] + alpha
#       n
#     , {}

class Params extends URLSearchParams
  fetch: (key, default_) ->
    value = @get key
    if value is null
      default_
    else
      if value.match /^\d/
        '#' + value
      else
        value

# primary.text.color=1565C0
# secondary.text.color=E91E63
export default ({ children }) ->
  location = do useLocation
  theme = useMemo ->
    params = new Params location.search
    createMuiTheme
      palette:
        type: 'dark'
        primary:
          main: params.fetch 'primary.color', deepPurple[700] + 'CC'
        secondary:
          main: params.fetch 'secondary.color', blueGrey[700] + '33'
        grey:
          300: params.fetch 'default.color', blueGrey[300] + 'CC'
  , [location.search]

  <ThemeProvider theme={theme}>
    {children}
  </ThemeProvider>
