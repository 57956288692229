import { Set } from 'immutable'

setLength = (length) ->
  if length <= 8
    1
  else if length <= 16
    2
  else if length <= 32
    4
  else
    throw new Error 'too long'

export setBin = (array) -> Object.freeze
  byteLength: setLength array.length
  pack: (set) ->
    return 0 unless set
    array.reduce (col, name, idx) ->
      if (set.has and set.has name) or (set.includes and set.includes name)
        col | Math.pow 2, idx
      else
        col
    , 0
  unpack: (value) ->
    Set array.reduce (arr, name, idx) ->
      if value & Math.pow 2, idx
        arr.push name
      arr
    , []

listLength = (length) ->
  if length <= 0xFE
    1
  else if length <= 0xFFFE
    2
  else if length <= 0xFFFFFFFE
    4
  else
    throw new Error 'too long'

export listBin = (array) -> Object.freeze
  byteLength: listLength array.length
  pack: (item) -> 1 + array.indexOf item
  unpack: (value) -> if value is 0 then '' else array[value - 1]

identity = (x) -> x
export uint8Bin = Object.freeze
  byteLength: 1
  pack: identity
  unpack: identity

export uint16Bin = Object.freeze
  byteLength: 2
  pack: identity
  unpack: identity

export uint32Bin = Object.freeze
  byteLength: 4
  pack: identity
  unpack: identity

read =
  1: 'getUint8'
  2: 'getUint16'
  4: 'getUint32'

write =
  1: 'setUint8'
  2: 'setUint16'
  4: 'setUint32'

# V8 has optimizations for (signed) integers that can be stored in 31 bits.
# We split at no more than 30 bits, the maximum positive size that will fit in a V8 small integer ("smi")
MASK = 0x3FFFFFFF
DIV = 0x40000000

packTime = (dataView, offset, value) ->
  return unless value instanceof Date
  t = do value.getTime
  lo = t & MASK
  hi = (t - lo) / DIV
  dataView.setUint32 offset, lo
  dataView[write[@byteLength - 4]] offset + 4, hi

unpackTime = (dataView, offset) ->
  lo = dataView.getUint32 offset
  hi = dataView[read[@byteLength - 4]] offset + 4
  return if lo is 0 and hi is 0
  new Date (hi & MASK) * DIV + (lo & MASK)

export time48Bin = Object.freeze
  byteLength: 6
  pack: packTime
  unpack: unpackTime

export time64Bin = Object.freeze
  byteLength: 8
  pack: packTime
  unpack: unpackTime

export default (coders) -> Object.freeze
  byteLength: Object.getOwnPropertyNames(coders).reduce (size, key) ->
    size + coders[key].byteLength
  , 0
  pack: (obj) ->
    arrayBuffer = new ArrayBuffer @byteLength
    dataView = new DataView arrayBuffer
    offset = 0
    Object.getOwnPropertyNames(coders).forEach (key, idx) ->
      coder = coders[key]
      if coder.byteLength <= 4
        dataView[write[coder.byteLength]] offset, coder.pack obj[key]
      else
        coder.pack dataView, offset, obj[key]
      offset += coder.byteLength
    arrayBuffer
  unpack: (arrayBuffer) ->
    unless arrayBuffer.byteLength is @byteLength
      throw new Error "invalid byteLength (not #{@byteLength})"
    dataView = new DataView arrayBuffer
    offset = 0
    Object.getOwnPropertyNames(coders).reduce (obj, key) ->
      coder = coders[key]
      if coder.byteLength <= 4
        value = dataView[read[coder.byteLength]] offset
        obj[key] = coder.unpack value
      else
        obj[key] = coder.unpack dataView, offset
      offset += coder.byteLength
      obj
    , {}
