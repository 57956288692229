import React, { Fragment, useEffect, useRef, useMemo, useState } from 'react'
import { Provider, useSelector, useStore } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

import { Evidence, EvidenceBar } from './evidence.coffee'
import { Objectives } from './objectives.coffee'
import AppBar from './app_bar.coffee'
import { GhostProfile } from './ghost_profile.coffee'

useStyles = makeStyles
  ghostGrid:
    display: 'grid'
    gridTemplateColumns: 'repeat(auto-fill, minmax(175px, 1fr))'
    gridGap: '5px'
    gridColumnGap: '5px'
    gridAutoFlow: 'dense'
  ghost:
    transition: 'opacity 1s ease'
    background: '#000'
  hide:
    display: 'none'

export Ghosts = ->
  classes = do useStyles
  confirmed = useSelector (state) -> state.contract.confirmed
  rejected = useSelector (state) -> state.contract.rejected
  possible = useSelector (state) -> state.possibleGhosts
  ghosts = useSelector (state) -> state.ghosts
  return null unless confirmed and rejected

  <div className={classes.ghostGrid}>
    {ghosts.map (ghost) ->
      <div
        key={ghost.name}
        className={clsx [classes.hide]: not possible.get(ghost)}
      >
        <Ghost
          className={classes.ghost}
          {...ghost}
        />
      </div>}
  </div>

useGhostStyles = makeStyles
  grid:
    display: 'grid'
    gridTemplateColumns: '1'
    gridGap: '5px'
    gridColumnGap: '5px'
export Ghost = ({name, description, strengths, weaknesses, evidence}) ->
  { t } = do useTranslation
  classes = do useGhostStyles
  <div>
    <h3>{name}</h3>
    <div className={classes.grid}>
      {evidence.map (ev) ->
        <Evidence
          key={ev}
          name={ev}
          variant='contained'
        />}
    </div>
    <p>{description}</p>
    <h4>{t 'strengths'}</h4>
    <p>{strengths}</p>
    <h4>{t 'weaknesses'}</h4>
    <p>{weaknesses}</p>
  </div>

import VoiceControl from './voice.coffee'

useDefaultStyles = makeStyles
  root:
    margin: '5px'
export default ->
  { t } = do useTranslation
  classes = do useDefaultStyles
  voiceControl = useSelector (state) -> state.voiceControl

  <Fragment>
    <AppBar />
    {voiceControl and <VoiceControl />}
    <div className={classes.root}>
      <GhostProfile />
      <div>
        <h2>{t 'objectives'}</h2>
        <Objectives />
      </div>
      <div>
        <h2>{t 'evidence'}</h2>
      </div>
      <EvidenceBar />
      <Ghosts />
    </div>
  </Fragment>
