import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  BrowserRouter as Router
  Switch
  Route
  Link
  useRouteMatch
  useParams
  useLocation
  useHistory
} from 'react-router-dom'

import CommandListener from './command_listener.coffee'
import GhostsApp from './ghosts.coffee'
import StreamApp from './stream.coffee'
import { RoomConnection } from './socket.coffee'
import MuiTheme from './mui_theme.coffee'

import { RESET_CONTRACT, CLEAR_CONTRACT } from './redux.coffee'
ResetGame = ({children = null})->
  dispatch = do useDispatch
  history = do useHistory
  useEffect ->
    # console.log 'ResetGame', history.location.state
    if history.action is 'POP'
      dispatch type: RESET_CONTRACT
  , [dispatch, history.location]
  children

ClearGame = ({children = null})->
  dispatch = do useDispatch
  history = do useHistory
  useEffect ->
    # console.log 'ClearGame', history.location.state
    dispatch type: CLEAR_CONTRACT
  , [dispatch, history.location]
  children

export default ->
  <Router>
    <MuiTheme>
      <Switch>
        <Route path='/room/:roomId/stream'>
          <RoomConnection />
          <StreamApp />
        </Route>
        <Route path='/room/:roomId'>
          <RoomConnection />
          <GhostsApp />
          <CommandListener />
        </Route>
        <Route path='/'>
          <ResetGame />
          <GhostsApp />
          <CommandListener />
        </Route>
      </Switch>
    </MuiTheme>
  </Router>
