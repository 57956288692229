import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'

export default ({children = null}) ->
  dispatch = do useDispatch
  callback = useCallback ({origin, data}) ->
    return if origin isnt location.origin
    console.log 'CommandListener', data
  , [dispatch]
  useEffect ->
    addEventListener 'message', callback
    -> removeEventListener 'message', callback
  , [callback]
  children
