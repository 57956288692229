import React, { Fragment, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { useHistory, useParams } from 'react-router-dom'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import MicIcon from '@material-ui/icons/Mic'
import Switch from '@material-ui/core/Switch'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CastIcon from '@material-ui/icons/Cast'


import { TOGGLE_VOICE_CONTROL } from './redux.coffee'

useStyles = makeStyles (theme) ->
  root:
    width: '100%'
    maxWidth: 360
    backgroundColor: theme.palette.background.paper
  text:
    marginRight: '30px'

export DrawerContent = ->
  { t } = do useTranslation
  voiceControl = useSelector (state) -> state.voiceControl
  dispatch = do useDispatch
  toggleVoiceControl = useCallback ->
    dispatch type: TOGGLE_VOICE_CONTROL
  , [dispatch]
  classes = do useStyles
  { roomId } = do useParams
  handleStream = useCallback -> navigator.clipboard.writeText "#{location.href}/stream"
  handleShare = useCallback -> navigator.clipboard.writeText "#{location.href}"

  items = [
    <ListItem key='voice'>
      <ListItemIcon>
        <MicIcon />
      </ListItemIcon>
      <ListItemText className={classes.text} primary={t 'voice_commands'} />
      <ListItemSecondaryAction>
        <Switch
          edge='end'
          color='primary'
          checked={voiceControl}
          onChange={toggleVoiceControl}
        />
      </ListItemSecondaryAction>
    </ListItem>
  ]

  if roomId
    items.push \
      <ListItem button key='room-url' onClick={handleShare}>
        <ListItemIcon>
          <FileCopyIcon />
        </ListItemIcon>
        <ListItemText className={classes.text} primary={t 'copy_room_url'} />
      </ListItem>

    items.push \
      <ListItem button key='stream-url' onClick={handleStream}>
        <ListItemIcon>
          <CastIcon />
        </ListItemIcon>
        <ListItemText className={classes.text} primary={t 'copy_stream_widget_url'} />
      </ListItem>

  <List className={classes.root} children={items} />
