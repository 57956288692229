import React, { Fragment, useEffect, useState, useCallback } from 'react'
import { useDispatch, useStore } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import { useHistory, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import AppBar from '@material-ui/core/AppBar'
import Badge from '@material-ui/core/Badge'
import Button from '@material-ui/core/Button'
import CastIcon from '@material-ui/icons/Cast'
import Drawer from '@material-ui/core/Drawer'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import PeopleIcon from '@material-ui/icons/People'
import RefreshIcon from '@material-ui/icons/Refresh'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'

import {
  RESET_CONTRACT
  SKIP_NEXT_SERVER_STATE
} from './redux.coffee'
import { SocketConnection } from './socket.coffee'
import Evidence from './evidence.coffee'
import { DrawerContent } from './drawer_content.coffee'
import SayingHints from './saying_hints.coffee'

useStyles = makeStyles (theme) ->
  grow:
    flexGrow: 1
  menuButton:
    marginRight: theme.spacing 2
  text: {}

ToggleOnline = ->
  { t } = do useTranslation
  { roomId } = do useParams
  history = do useHistory
  dispatch = do useDispatch

  if roomId
    handleOffline = -> history.push '/'
    # <Badge badgeContent={4} color='error'>
    #   <PeopleIcon />
    # </Badge>
    <Button color='inherit' onClick={handleOffline}>{t 'go_offline'}</Button>
  else
    handleClick = ->
      uuid = do uuidv4
      history.push "/room/#{uuid}"
      dispatch type: SKIP_NEXT_SERVER_STATE
    <Button color='inherit' onClick={handleClick}>{t 'go_online'}</Button>

export default ->
  classes = do useStyles
  { t } = do useTranslation
  [drawerOpen, setDrawerOpen] = useState false
  openDrawer = useCallback (-> setDrawerOpen true), [setDrawerOpen]
  closeDrawer = useCallback (-> setDrawerOpen false), [setDrawerOpen]

  <AppBar position='static'>
    <Toolbar>
      <IconButton
        edge='start'
        className={classes.menuButton}
        onClick={openDrawer}
        children={<MenuIcon />}
      />
      <Drawer anchor='left' open={drawerOpen} onClose={closeDrawer}>
        <DrawerContent />
      </Drawer>
      <div className={classes.grow}>
        <Typography variant='h6'>
          {t 'title'}
        </Typography>
        <Typography variant='caption' className={classes.text}>
          <SayingHints />
        </Typography>
      </div>
      <ToggleOnline />
    </Toolbar>
  </AppBar>
