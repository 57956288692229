import React, { Fragment, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import clsx from  'clsx'
import Button from '@material-ui/core/Button'

import { TOGGLE_OBJECTIVE } from './redux.coffee'

export objectives = Object.freeze [
  'Candle'
  'Crucifix'
  'EMF'
  'Escape'
  'Event'
  'Motion'
  'Photo'
  'Salt'
  'Sanity'
  'Smudge Area'
  'Smudge Hunt'
]

useStyles = makeStyles
  grid:
    display: 'grid'
    gridTemplateColumns: 'repeat(auto-fit, minmax(135px, 1fr))'
    gridGap: '5px'
    gridColumnGap: '5px'

export Objectives = ->
  classes = do useStyles
  dispatch = do useDispatch
  selected = useSelector (state) -> state.contract.objectives
  return null unless selected

  <div className={classes.grid}>
    {objectives.map (objective) ->
      handleClick = -> dispatch type: TOGGLE_OBJECTIVE, name: objective
      <Button
        key={objective}
        onClick={handleClick}
        variant='contained'
        color={'primary' if selected.has objective}
      >
        {objective}
      </Button>}
  </div>
