import React, { useRef, useLayoutEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'

pad = (n) ->
  if n < 10
    "0#{n}"
  else
    "#{n}"

export default ({t0, showMs = false}) ->
  ref = do useRef

  layoutEffect = useCallback ->
    if typeof t0 is 'undefined'
      str = '?'
    else
      delta = (new Date) - t0
      ss = Math.floor delta / 1000 % 60
      mm = Math.floor delta / 60 / 1000 % 60
      str = "#{pad mm}:#{pad ss}"
      h = Math.floor delta / 60 / 60 / 1000 % 60
      str = "#{h}:#{str}" if h > 0
      if showMs
        ms = Math.floor delta / 100 % 10
        str = "#{str}.#{ms}"

    ref.current.innerHTML = str
  , [ref, showMs, t0]

  useLayoutEffect ->
    do layoutEffect
    id = setInterval layoutEffect, if showMs then 100 else 1000
    -> clearInterval id
  , [layoutEffect, showMs]

  <span ref={ref} />
