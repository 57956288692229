import React, { Fragment, useRef, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import { Provider, useSelector, useDispatch, useStore } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import clsx from  'clsx'

import {
  TOGGLE_CONFIRM_EVIDENCE
  TOGGLE_REJECT_EVIDENCE
} from './redux.coffee'

useStyles = makeStyles
  confirmed:
    lineSpacing: 1
  rejected:
    textDecoration: 'line-through !important'

export Evidence = ({name, variant}) ->
  confirmed = useSelector (state) -> state.contract.confirmed
  rejected = useSelector (state) -> state.contract.rejected
  dispatch = do useDispatch
  classes = do useStyles
  return null unless confirmed and rejected

  handleClick = ({altKey, ctrlKey, metaKey, shiftKey, button}) ->
    invert = altKey or ctrlKey or metaKey or shiftKey or button is 1
    if invert is false and confirmed.size > 2 and not confirmed.has name
      console.warn 'too many confirmed evidences'
      return
    dispatch
      type: if invert then TOGGLE_REJECT_EVIDENCE else TOGGLE_CONFIRM_EVIDENCE
      name: name

  color =
    if confirmed.has name
      'primary'
    else if rejected.has name
      'secondary'

  classNames = clsx
    [classes.confirmed]: confirmed.has name
    [classes.rejected]: rejected.has name

  <Button
    variant={variant}
    className={classNames}
    onClick={handleClick}
    onAuxClick={handleClick}
    color={color}
  >
    {name}
  </Button>

useEvidenceBarStyles = makeStyles
  evidenceGrid:
    display: 'grid'
    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))'
    gridGap: '5px'
    gridColumnGap: '5px'
    gridAutoFlow: 'dense'
  evidence:
    paddingLeft: '8px'
    paddingRight: '8px'
  hide:
    visibility: 'hidden'

export EvidenceBar = ->
  classes = do useEvidenceBarStyles
  evidence = useSelector (state) -> state.evidence
  possible = useSelector (state) -> state.possibleEvidence

  <div className={classes.evidenceGrid}>
    {evidence.map (ev) ->
      if possible.has ev
        <Evidence
          key={ev}
          name={ev}
          className={classes.evidence}
          variant='contained'
        />
      else
        <div key={ev} />
    }
  </div>
