import React, { Fragment, useRef, useState, useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import clsx from  'clsx'

import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'
import PersonIcon from '@material-ui/icons/Person'
import PeopleIcon from '@material-ui/icons/People'

import StreamTimer from './stream/timer.coffee'

useStyles = makeStyles
  title:
    display: 'inline-flex'
  flex:
    display: 'inline-flex'
    flex: 1
    alignItems: 'center'
    overflow: 'hidden'
  grow:
    flexGrow: 1
  span:
    marginRight: '5px'
  grid:
    display: 'grid'
    gridTemplateColumns: 'max-content max-content auto max-content max-content'
    gridGap: '5px'
    gridColumnGap: '15px'
    margin: '3px'
  grid2:
    display: 'grid'
    gridTemplateColumns: 'max-content auto max-content'
    gridGap: '5px'
    gridColumnGap: '15px'
    margin: '3px'
  rejected:
    textDecoration: 'line-through !important'
  scroll:
    position: 'relative'
    margin: 'auto'
    whiteSpace: 'nowrap'
    animation: '$scroll ease-in infinite'
    # right: 'var(--me)'
  "@keyframes scroll":
    "0%":
      right: '0'
    "75%":
      right: '0'
    "80%":
      right: 'var(--me)'
    "97%":
      right: 'var(--me)'
    "100%":
      right: '0'

export default ->
  { t } = do useTranslation
  classes = do useStyles

  ghosts = useSelector (state) -> state.ghosts
  evidences = useSelector (state) -> state.evidence
  possibleGhosts = useSelector (state) -> state.possibleGhosts
  suspectGhosts = useSelector (state) -> state.suspectGhosts
  possibleEvidence = useSelector (state) -> state.possibleEvidence

  ghostName = useSelector (state) -> state.contract.name
  objectives = useSelector (state) -> state.contract.objectives
  personality = useSelector (state) -> state.contract.personality
  confirmed = useSelector (state) -> state.contract.confirmed
  rejected = useSelector (state) -> state.contract.rejected
  name = useSelector (state) -> state.contract.map
  level = useSelector (state) -> state.contract.level

  startedAt = useSelector (state) -> state.contract.startedAt
  timer = useSelector (state) -> state.contract.timer

  wrapperEle = do useRef
  ghostsEle = do useRef
  [scrollWidth, setScrollWidth] = do useState
  [scrollSpeed, setScrollSpeed] = do useState
  useLayoutEffect ->
    return unless wrapperEle.current and ghostsEle.current
    # { scrollWidth, clientWidth } = wrapperEle.current
    offset = (do ghostsEle.current.getBoundingClientRect).width
    offset -= (do wrapperEle.current.getBoundingClientRect).width
    if offset <= 10
      setScrollWidth '0'
      setScrollSpeed '0'
    else
      setScrollSpeed "#{offset / 10}s"
      setScrollWidth "#{offset}px"
  , [wrapperEle.current, ghostsEle.current, possibleGhosts, suspectGhosts]

  return null unless objectives

  displayGhosts = ghosts.map (ghost) ->
    last = Array.from(do possibleGhosts.values).filter((x)->x).length is 1
    if possibleGhosts.get ghost
      <Chip
        key={ghost.name}
        className={clsx classes.span, [classes.rejected]: suspectGhosts.get(ghost)}
        size='small'
        color={if last then 'primary' else if suspectGhosts.get ghost then 'secondary'}
        label={ghost.name} />
    else
      null

  objectives = Array.from objectives
  displayObjectives =
    if objectives.length > 0
      objectives.map (objective) ->
        <Chip
          key={objective}
          className={classes.span}
          size='small'
          color='secondary'
          label={objective} />
    else
      <Chip
        className={classes.span}
        size='small'
        label={'?'} />

  <Fragment>
    <div className={classes.grid}>
      <div className={classes.flex}>
        <Chip
          icon={if personality is 'Alone' then <PersonIcon /> else <PeopleIcon />}
          size='small'
          color={'secondary' if ghostName}
          label={ghostName or 'Unknown'}
        />
      </div>

      <div className={classes.flex}>
        {evidences.map (evidence) ->
          if possibleEvidence.get evidence
            color =
              if confirmed.has evidence
                'primary'
              else if rejected.has evidence
                'secondary'
            className = clsx classes.span,
              [classes.rejected]: rejected.has(evidence)
            <Chip
              key={evidence}
              className={className}
              size='small'
              label={evidence}
              color={color} />
          else
            null
      }</div>

      <div className={classes.flex} ref={wrapperEle}>
        <div
          className={classes.scroll}
          style={{ animationDuration: scrollSpeed, '--me': scrollWidth }}
          ref={ghostsEle}
          children={displayGhosts}/>
      </div>

      <div className={classes.flex}>
        {displayObjectives}
      </div>

      <Chip
        className={classes.flex}
        label={<StreamTimer t0={startedAt} />}
        size='small'
        color={'secondary'}
      />
    </div>

    <div className={classes.grid2}>
      <Chip
        className={classes.flex}
        label={"#{name or '?'} / #{level or '?'}" }
        size='small'
        color={'secondary'}
      />
      <div className={classes.grow} />
      {timer and <Chip
        className={classes.flex}
        label={<StreamTimer t0={timer} />}
        size='small'
        color={'secondary'}
      />}
    </div>
  </Fragment>
