export bufferEqual = (a, b) ->
  if a instanceof ArrayBuffer
    a = new Uint8Array a, 0
  if b instanceof ArrayBuffer
    b = new Uint8Array b, 0
  return false if a.byteLength isnt b.byteLength
  mod = a.byteLength % 4
  len = a.byteLength - mod
  au32 = new Uint32Array a.buffer, 0, len / 4
  bu32 = new Uint32Array b.buffer, 0, len / 4
  return false unless compare au32, bu32
  au8 = new Uint8Array a.buffer, len, mod
  bu8 = new Uint8Array b.buffer, len, mod
  compare au8, bu8

compare = (a, b) ->
  i = a.length
  while -1 < i
    if a[i] isnt b[i]
      return false
    i--
  true
