import React, { Fragment, useEffect, useRef, useMemo, useState, useCallback } from 'react'
import { Provider, useSelector, useDispatch, useStore } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { matchSorter } from 'match-sorter'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

import { GhostNameInput } from './ghost_name_input.coffee'
import {
  RESET_CONTRACT
  TOGGLE_ALONE_GHOST
  SET_MAP
  SET_LEVEL
  MAPS
  LEVELS
} from './redux.coffee'

export MapInput = ->
  value = useSelector (store) -> store.contract.map
  dispatch = do useDispatch
  onChange = useCallback ({target}) ->
    dispatch type: SET_MAP, value: target.value
  , [dispatch]

  <Select
    value={value or ''}
    onChange={onChange}
    children={MAPS.map (map) ->
      <MenuItem value={map} key={map} children={map} />
    }
  />

export LevelInput = ->
  value = useSelector (store) -> store.contract.level
  dispatch = do useDispatch
  onChange = useCallback ({target}) ->
    dispatch type: SET_LEVEL, value: target.value
  , [dispatch]

  <Select
    value={value or ''}
    onChange={onChange}
    children={LEVELS.map (level) ->
      <MenuItem value={level} key={level} children={level} />
    }
  />


useStyles = makeStyles
  root:
    display: 'flex'
    flexWrap: 'wrap'
    gridGap: '3px'
  button:
    marginTop: 'auto'

# Ghost will have a randomly generated unique identity consisting of gender,
# name, death length, age, shyness, favorite room, and a physical model
# that is determined at level start.
export GhostProfile = ->
  { t } = do useTranslation
  dispatch = do useDispatch
  classes = do useStyles
  personality = useSelector (store) -> store.contract.personality
  level = useSelector (store) -> store.contract.level

  handleAlone = (event) -> dispatch type: TOGGLE_ALONE_GHOST
  handleReset = (event) -> dispatch type: RESET_CONTRACT

  <div className={classes.root}>
    <GhostNameInput />
    <MapInput />
    <LevelInput />
    {level isnt 'Professional' && <Button
      className={classes.button}
      variant='contained'
      onClick={handleAlone}
      color={'primary' if personality is 'Alone'}
      children={t 'alone'}
    />}
    <Button
      className={classes.button}
      variant='contained'
      onClick={handleReset}
      children={t 'reset'}
    />
  </div>
