import { Set, is as same } from 'immutable'
import { createSelector } from 'reselect'

import Ghosts from './ghosts.json'
import GhostNames from './ghost_names.coffee'
import Symbol from './data/symbol.coffee'
import Bin, { listBin, setBin, uint8Bin, uint16Bin, uint32Bin, time48Bin, time64Bin } from './binary.coffee'
import { objectives as OBJECTIVES } from './objectives.coffee'

export CLEAR_CONTRACT = Symbol 'CLEAR_CONTRACT'
export CONFIRM_EVIDENCE = Symbol 'CONFIRM_EVIDENCE'
export REJECT_EVIDENCE = Symbol 'REJECT_EVIDENCE'
export RESET_CONTRACT = Symbol 'RESET_CONTRACT'
export SERVER_STATE = Symbol 'SERVER_STATE'
export SET_GHOST_NAME = Symbol 'SET_GHOST_NAME'
export SET_LEVEL = Symbol 'SET_LEVEL'
export SET_MAP = Symbol 'SET_MAP'
export SKIP_NEXT_SERVER_STATE = Symbol 'SKIP_NEXT_SERVER_STATE'
export TIMER_CLEAR = Symbol 'TIMER_CLEAR'
export TIMER_START = Symbol 'TIMER_START'
export TOGGLE_ALONE_GHOST = Symbol 'TOGGLE_ALONE_GHOST'
export TOGGLE_CONFIRM_EVIDENCE = Symbol 'TOGGLE_CONFIRM_EVIDENCE'
export TOGGLE_OBJECTIVE = Symbol 'TOGGLE_OBJECTIVE'
export TOGGLE_REJECT_EVIDENCE = Symbol 'TOGGLE_REJECT_EVIDENCE'
export TOGGLE_VOICE_CONTROL = Symbol 'TOGGLE_VOICE_CONTROL'

calcMetadata = (ghosts, evidence, confirmed, rejected) ->
  score = new Map
  possibleGhosts = new Map
  suspectGhosts = new Map
  possibleEvidence = do Set
  ghosts.forEach (ghost) ->
    # Calculate Possible Ghosts
    hasAllEvidence = confirmed.every (evidence) ->
      ghost.evidence.includes evidence
    possibleGhosts.set ghost, hasAllEvidence
    suspectGhosts.set ghost, rejected.some (evidence) ->
      ghost.evidence.includes evidence

    # If the ghost is possible, update Possible Evidence
    if hasAllEvidence
      ghost.evidence.forEach (evidence) ->
        possibleEvidence = possibleEvidence.add evidence

    # Calculate Evidence Scores
    score.set ghost,
      ghost.evidence.reduce (weight, evidence) ->
        weight++ if confirmed.has evidence
        weight-- if rejected.has evidence
        weight
      , 0

  # Stable-sort ghosts by evidence scores
  ghosts = [...ghosts].sort (a, b) -> score.get(b) - score.get(a)

  # Alphabetical-sort evidence, then sort by possibility
  evidence = [...evidence]
    .sort()
    .sort (a, b) -> if possibleEvidence.has b then 1 else -1

  {
    ghosts
    possibleGhosts
    suspectGhosts
    evidence
    possibleEvidence
  }

export EVIDENCE = Object.freeze [
  'EMF Level 5'
  'Fingerprints'
  'Freezing Temps'
  'Ghost Orb'
  'Ghost Writing'
  'Spirit Box'
]

export MAPS = Object.freeze [
  'Tanglewood Street House'
  'Edgefield Street House'
  'Ridgeview Road House'
  'Grafton Farmhouse'
  'Bleasdale Farmhouse'
  'Willow Street House'
  'Brownstone High School'
  'Prison'
  'Asylum'
]

export LEVELS = Object.freeze [
  'Amateur'
  'Intermediate'
  'Professional'
]

export PERSONALITY = Object.freeze [
  'Alone'
  'Everyone'
]

Msg = Bin
  startedAt: time48Bin
  timer: time48Bin
  objectives: setBin OBJECTIVES
  confirmed: setBin EVIDENCE
  rejected: setBin EVIDENCE
  name: listBin GhostNames
  map: listBin MAPS
  level: listBin LEVELS
  personality: listBin PERSONALITY

export contractSelector = createSelector [
  (state) -> state.contract.startedAt
  (state) -> state.contract.timer
  (store) -> store.contract.confirmed
  (store) -> store.contract.level
  (store) -> store.contract.map
  (store) -> store.contract.name
  (store) -> store.contract.objectives
  (store) -> store.contract.personality
  (store) -> store.contract.rejected
], (startedAt, timer, confirmed, level, map, name, objectives, personality, rejected) ->
  Msg.pack {startedAt, timer, confirmed, level, map, name, objectives, personality, rejected}

initialState = ->
  evidence: EVIDENCE
  startedAt: new Date
  possibleEvidence: Set EVIDENCE
  ghosts: Ghosts
  contract: {}
  voiceControl: false

export default (state = do initialState, action) ->
  console.debug action
  switch action.type
    when TOGGLE_ALONE_GHOST
      {
        ...state
        contract: {
          ...state.contract
          personality: if state.contract.personality isnt 'Alone'
              'Alone'
            else
              'Everyone'
        }
      }
    when TOGGLE_VOICE_CONTROL
      {
        ...state
        voiceControl: not state.voiceControl
      }
    when SET_GHOST_NAME
      {
        ...state
        contract: {
          ...state.contract
          name: action.value
        }
      }
    when SET_MAP
      {
        ...state
        contract: {
          ...state.contract
          map: action.value
        }
      }
    when SET_LEVEL
      {
        ...state
        contract: {
          ...state.contract
          level: action.value
        }
      }
    when REJECT_EVIDENCE
      { confirmed, rejected } = state.contract
      confirmed = confirmed.delete action.name
      rejected = rejected.add action.name
      {
        ...state
        contract: {
          ...state.contract
          confirmed
          rejected
        }
        ...calcMetadata state.ghosts, state.evidence, confirmed, rejected
      }
    when TOGGLE_REJECT_EVIDENCE
      { confirmed, rejected } = state.contract
      if rejected.has action.name
        rejected = rejected.delete action.name
      else
        confirmed = confirmed.delete action.name
        rejected = rejected.add action.name
      {
        ...state
        contract: {
          ...state.contract
          confirmed
          rejected
        }
        ...calcMetadata state.ghosts, state.evidence, confirmed, rejected
      }
    when CONFIRM_EVIDENCE
      { confirmed, rejected } = state.contract
      confirmed = confirmed.add action.name
      rejected = rejected.delete action.name
      {
        ...state
        contract: {
          ...state.contract
          confirmed
          rejected
        }
        ...calcMetadata state.ghosts, state.evidence, confirmed, rejected
      }
    when TOGGLE_CONFIRM_EVIDENCE
      { confirmed, rejected } = state.contract
      if confirmed.has action.name
        confirmed = confirmed.delete action.name
      else
        confirmed = confirmed.add action.name
        rejected = rejected.delete action.name
      {
        ...state
        contract: {
          ...state.contract
          confirmed
          rejected
        }
        ...calcMetadata state.ghosts, state.evidence, confirmed, rejected
      }
    when TOGGLE_OBJECTIVE
      objectives = state.contract.objectives
      {
        ...state
        contract: {
          ...state.contract
          objectives: if objectives.has action.name
            objectives.delete action.name
          else
            objectives.add action.name
        }
      }
    when SKIP_NEXT_SERVER_STATE
      {
        ...state
        skipNextServerState: true
      }
    when SERVER_STATE
      if state.skipNextServerState
        return {
          ...state
          skipNextServerState: false
        }
      contract = Msg.unpack action.data
      {
        ...state
        contract
        ...calcMetadata state.ghosts, state.evidence, contract.confirmed, contract.rejected
      }
    when CLEAR_CONTRACT
      {
        ...state
        contract: {}
      }
    when RESET_CONTRACT
      confirmed = do Set
      rejected = do Set
      {
        ...state
        contract: {
          name: ''
          startedAt: new Date
          objectives: do Set
          confirmed
          rejected
          map: state.contract.map
          level: state.contract.level
        }
        ...calcMetadata state.ghosts, state.evidence, confirmed, rejected
      }
    when TIMER_START
      {
        ...state
        contract: {
          ...state.contract
          timer: new Date
        }
      }
    when TIMER_CLEAR
      {
        ...state
        contract: {
          ...state.contract
          timer: undefined
        }
      }
    else
      unless typeof action.type is 'string' and action.type.startsWith '@@redux/INIT'
        console.warn "Unknown action in redux reducer:", action.type
        console.debug action
      state
