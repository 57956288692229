import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import loadGameI18n from './i18n_game.coffee'

i18n
  .use initReactI18next
  .init
    # debug: true
    lng: 'en'
    fallbackLng: 'en'
    interpolation:
      escapeValue: false
    parseMissingKeyHandler: (key) ->
      console.warn "Translation '#{key}' not found."
      key
    resources:
      en:
        translation:
          title: 'Phasmomania'
          reset: 'Reset'
          alone: 'Alone'
          go_offline: 'Go Offline'
          go_online: 'Go Online'
          ghost_name: 'Ghost Name'
          objectives: 'Objectives'
          evidence: 'Evidence'
          strengths: 'Strengths'
          weaknesses: 'Weaknesses'
          copy_stream_widget_url: 'Copy Stream Widget URL'
          copy_room_url: 'Copy Room URL'
          voice_commands: 'Voice Commands'

do loadGameI18n
