import 'typeface-roboto'
import React from 'react'
import { render } from 'react-dom'

import './style.sass'
import './i18n.coffee'
import Router from './router.coffee'

import { Provider } from 'react-redux'
import { createStore } from 'redux'
import ReduxReducers from './redux.coffee'

# import B from './sanity.coffee'
# do B

# Bootstrap React
root = document.createElement 'div'
document.body.prepend root

render \
  <Provider store={createStore ReduxReducers}>
    <Router />
  </Provider>,
  root
