import { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { phraisesHints } from './i18n_game.coffee'

sample = -> phraisesHints[Math.floor phraisesHints.length * do Math.random]

export default ->
  { t } = useTranslation 'phasmophobia'
  [hint, setHint] = useState do sample
  cb = useCallback -> setHint do sample
  useEffect ->
    id = setInterval cb, 4000
    -> clearInterval id
  , [cb]


  text = t hint
  text = do text[0].toUpperCase + text.slice 1
  if hint.startsWith 'Ouija_'
    "Ouija: #{text}?"
  else if hint.startsWith 'Loading_Hint'
    "Hint: #{text}"
  else if hint.startsWith 'Q_'
    "Spirit Box: #{text}?"
  else if hint.startsWith 'Phrase_'
    "Trigger: #{text}."
  else
    "#{text}?"
