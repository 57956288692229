
import React, { useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { bufferEqual } from './util.coffee'
import { contractSelector, SERVER_STATE } from './redux.coffee'

export RoomConnection = ->
  { roomId } = do useParams
  <SocketConnection room={roomId} />

# ab2str = TextDecoder::decode.bind new TextDecoder
# str2ab = TextEncoder::encode.bind new TextEncoder

export SocketConnection = ({room, children = null}) ->
  dispatch = do useDispatch
  lastMessage = useRef new Uint8Array
  p = do useRef
  useEffect ->
    conn = undefined
    safeExit = false
    do connect = ->
      p.current = new Promise (resolve, reject) ->
        console.debug "Socket Connection opening(#{room})"
        secure = location.protocol.slice 4
        conn = new WebSocket "ws#{secure}//#{location.host}/ws?#{room}"
        conn.binaryType = 'arraybuffer'
        conn.onopen = -> resolve conn.send.bind conn
        conn.onerror = (error) ->
          console.debug "Socket Connection error(#{room}) = #{error}"
          reject error
        conn.onclose = ->
          unless safeExit
            setTimeout connect, 500
          console.debug "Socket Connection closed(#{room})"
        conn.onmessage = ({data}) ->
          return if bufferEqual lastMessage.current, data
          lastMessage.current = data
          dispatch type: SERVER_STATE, data: data
    ->
      safeExit = true
      do conn.close
  , [dispatch]

  snss = useSelector (store) -> store.skipNextServerState
  contract = useSelector contractSelector
  contractBytes = Array.from new Uint8Array contract

  deps = [snss, ...contractBytes]
  useEffect ->
    return unless deps.some (i) -> i
    return if bufferEqual lastMessage.current, contract
    lastMessage.current = contract
    p.current.then (send) -> send contract
  , deps

  children
